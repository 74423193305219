import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CandidateModel } from "./candidate-model";
import { customValidators } from "../factory/common.service";


export interface CandidateQualificationApproval {
    candidate: {
      id: number;
      firstName: string;
      lastName: string;
      jobRequisitionId: number;
    };
    id: number;
    currentDesignation: string;
    approverActionComment: any;
  }


  export interface experienceInMonth{
    candidate:CandidateModel;
  }

export interface CandidateQualificationModel {

    candidate: {
        id: number;
        jobRequisitionId: number;
        skills: Array<number>;
        requiredQualifications: Array<number>;
        expectedSalary: string;
         currentSalary: string;
        reasonToChange: string;

    },
    // id: number;
    jobRequisitionId: number;
    totalExperience: String;
    currentDesignation: string;
    approverActionComment: string;
    candidateWorkPreference: CandidateWorkPreference;
    certifications: string[];
    currentCompany: string;
    noticePeriod: number;
    currentAddess: string;
    homeTown: string;
    candidateQulificationApprovers: number[];
    remark: string;
    
}

export enum CandidateWorkPreference {
    WFO,
    WFH,
    HYBRID
}
 

  export class candidateQualificationFormBuilder{
    [x: string]: any;
    candidateQualificationModel: CandidateQualificationModel = {
        candidate:{
            id: null,
            jobRequisitionId: null,
            skills: [],
            requiredQualifications: [],
            expectedSalary: '',
            currentSalary: '',
            reasonToChange: '',
        },

        jobRequisitionId: null,
        totalExperience: '',
        currentDesignation: '',
        approverActionComment: '',
        certifications: [],
        currentCompany: '',
        noticePeriod: 0,
        currentAddess: '',
        homeTown: '',
        candidateQulificationApprovers: [],
        remark: '',
        candidateWorkPreference: CandidateWorkPreference.WFO
    }
    

    candidateForm: FormGroup = new FormGroup({
        jobRequisitionId: new FormControl(this.candidateQualificationModel.candidate.jobRequisitionId, [Validators.required]),
        skills: new FormControl(this.candidateQualificationModel.candidate.skills, [Validators.required]),
        requiredQualifications: new FormControl(this.candidateQualificationModel.candidate.requiredQualifications, [Validators.required]),
        expectedSalary: new FormControl(this.candidateQualificationModel.candidate.expectedSalary, [Validators.required ,customValidators.salaryValidator()]),
        currentSalary: new FormControl(this.candidateQualificationModel.candidate.currentSalary, [customValidators.salaryValidator()]),
        reasonToChange: new FormControl(this.candidateQualificationModel.candidate.reasonToChange),
        jobRequisitionIdQ: new FormControl(this.candidateQualificationModel.jobRequisitionId),
        totalExperience:new FormControl(this.candidateQualificationModel.totalExperience, [Validators.required]),
        currentDesignation:new FormControl(this.candidateQualificationModel.currentDesignation),
        approverActionComment:new FormControl(this.candidateQualificationModel.approverActionComment),
        candidateWorkPreference:new FormControl(this.candidateQualificationModel.candidateWorkPreference, [Validators.required]),
        certifications:new FormControl(this.candidateQualificationModel.certifications),
        currentCompany:new FormControl(this.candidateQualificationModel.currentCompany),
        noticePeriod:new FormControl(this.candidateQualificationModel.noticePeriod, [Validators.required]),
        currentAddess:new FormControl(this.candidateQualificationModel.currentAddess, [Validators.required]),
        homeTown:new FormControl(this.candidateQualificationModel.homeTown, [Validators.required]),
        candidateQulificationApprovers:new FormControl(this.candidateQualificationModel.candidateQulificationApprovers, [Validators.required]),
        remark:new FormControl(this.candidateQualificationModel.remark),


    });

    constructor() {
        this.candidateForm.valueChanges.subscribe(val => {
           
            this.candidateQualificationModel.totalExperience = val.experienceInMonth;
            this.candidateQualificationModel.candidate.jobRequisitionId = val.jobRequisitionId;
            this.candidateQualificationModel.candidate.skills = val.skills;
            this.candidateQualificationModel.candidate.requiredQualifications = val.requiredQualifications;
            this.candidateQualificationModel.candidate.expectedSalary = val.expectedSalary;
            this.candidateQualificationModel.candidate.currentSalary = val.currentSalary;
            this.candidateQualificationModel.candidate.reasonToChange = val.reasonToChange;
            this.candidateQualificationModel.jobRequisitionId = val.jobRequisitionIdQ
            this.candidateQualificationModel.totalExperience = val.totalExperience
            this.candidateQualificationModel.currentDesignation = val.currentDesignation
            this.candidateQualificationModel.approverActionComment = val.approverActionComment
            this.candidateQualificationModel.candidateWorkPreference = val.candidateWorkPreference
            this.candidateQualificationModel.certifications = val.certifications
            this.candidateQualificationModel.currentCompany = val.currentCompany
            this.candidateQualificationModel.noticePeriod = val.noticePeriod
            this.candidateQualificationModel.currentAddess = val.currentAddess
            this.candidateQualificationModel.homeTown = val.homeTown
            this.candidateQualificationModel.candidateQulificationApprovers = val.candidateQulificationApprovers
            this.candidateQualificationModel.remark = val.remark

        });
    }

    setModelVals(res: CandidateQualificationModel) {


        let experienceInYear = Math.floor(Number(res.totalExperience)/12);
        let year = Number(res.totalExperience)%12;
        this.candidateForm.controls.experienceInMonth.setValue(experienceInYear + "." + year);
        this.candidateForm.controls.jobRequisitionId.setValue(res.candidate.jobRequisitionId);
        this.candidateForm.controls.skills.setValue(res.candidate.skills);
        this.candidateForm.controls.requiredQualifications.setValue(res.candidate.requiredQualifications);
        this.candidateForm.controls.expectedSalary.setValue(res.candidate.expectedSalary);
        this.candidateForm.controls.currentSalary.setValue(res.candidate.currentSalary);
        this.candidateForm.controls.reasonToChange.setValue(res.candidate.reasonToChange);
        this.candidateForm.controls.jobRequisitionIdQ.setValue(res.jobRequisitionId)
        this.candidateForm.controls.currentDesignation.setValue(res.currentDesignation)
        this.candidateForm.controls.approverActionComment.setValue(res.approverActionComment)
        this.candidateForm.controls.candidateWorkPreference.setValue(res.candidateWorkPreference)
        this.candidateForm.controls.certifications.setValue(res.certifications)
        this.candidateForm.controls.currentCompany.setValue(res.currentCompany)
        this.candidateForm.controls.noticePeriod.setValue(res.noticePeriod)
        this.candidateForm.controls.currentAddess.setValue(res.currentAddess)
        this.candidateForm.controls.homeTown.setValue(res.homeTown)
        this.candidateForm.controls.candidateQulificationApprovers.setValue(res.candidateQulificationApprovers)
        this.candidateForm.controls.remark.setValue(res.remark)


    }
  }