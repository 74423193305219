import { Component, OnInit } from '@angular/core';
import { CandidateTagsService } from './../../core/services/candidate-tags.service';
import { CandidateModel, CandidateFormBuilder } from '../../shared/models/candidate-model';
import { EmployerDetailsModel, EmployerDetailsFormBuilder } from '../../shared/models/employer-details-model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CandidateService } from '../../core/services/candidate.service';
import { FormGroup } from '@angular/forms';
import { CommonService, compareFn, customValidators } from '../../shared/factory/common.service';
import { fetchCachedData } from '../../shared/factory/data-caching.service';
import { AppConfigService } from '../../configs/app-config.service';
import { MessageService } from '../../shared/factory/message.service';
import { Location } from '@angular/common';
import { RecruiterService } from '../../core/services/recruiter.service';
import { CandidateQualificationService } from 'src/app/core/services/candidate-qualification.service';
import { FormControl } from '@angular/forms';
import { CandidateQualificationApproval, candidateQualificationFormBuilder, CandidateQualificationModel, CandidateWorkPreference } from 'src/app/shared/models/candidate-qualification-model';
@Component({
  selector: 'app-candidate-qualification-add',
  templateUrl: './candidate-qualification-add.component.html',
  styleUrls: ['./candidate-qualification-add.component.css']
})
export class CandidateQualificationAddComponent implements OnInit {
  candidate: CandidateFormBuilder = new CandidateFormBuilder();
  candidateModel: CandidateModel = this.candidate.candidateModel;
  candidateForm: FormGroup = this.candidate.candidateForm;
  countries = [];
  qualifications = fetchCachedData('qualifications');
  skills = fetchCachedData('skills');
  recruiters = fetchCachedData('recruiters');
  jobRequisitions = fetchCachedData('jobRequisitions');
  auth_token = sessionStorage.getItem('x-auth-token');
  compareFn = compareFn;
  selectedFileName = "";
  resumeFile: any = null;
  candidateTags = fetchCachedData('candidateTags');
  WorkPreference = CandidateWorkPreference;
  workPreferenceOptions: { key: string, value: number }[];

  employees = [];



  candidateQualification:candidateQualificationFormBuilder = new candidateQualificationFormBuilder();
  candidateQualificationModel:CandidateQualificationModel  =this.candidateQualification.candidateQualificationModel
  candidateQualificationForm:FormGroup  = this.candidateQualification.candidateForm;
  certifications = this.candidateQualificationModel.certifications
  chips: string[] = [];
  selectable = true;
  removable = true;


  constructor(  private route: ActivatedRoute,
    public router: Router,
    private candidateService: CandidateService,
    private _commonServices: CommonService,
    public _configUrls: AppConfigService,
    private messageService: MessageService,
    public location: Location,
    private candidateTagsService: CandidateTagsService,
  private candidteQualificationService:CandidateQualificationService) { }

  ngOnInit(): void {
    this._commonServices.fetchCountries().subscribe(res => {
      this.countries = res.countries;
    });
    this.candidateModel.id = parseInt(this.route.snapshot.paramMap.get('id'), 0);
    this.candidateModel.id ? this.fetchCandidate() : null;
   // console.log(this.candidateModel.id)
    this.fetchCandidateTag();   
    this.fetchEmployees();
    this.workPreferenceOptions = Object.keys(this.WorkPreference)
      .filter(key => isNaN(Number(key)))
      .map(key => ({ key, value: this.WorkPreference[key] }));


    // this.WorkPreference['WFO'] = "WFO";
    // this.WorkPreference['WFH'] = "WFH"
    // this.WorkPreference['HYBRID'] = 'HYBRID';
  }
  fetchCandidate() {
    this.candidateService.fetchCandidate(this.candidateModel.id).subscribe((res: CandidateModel) => {
      this.candidate.setModelVals(res);
    });
  }


  fetchEmployees() {
    this.candidteQualificationService.fetchEmployeesLite().subscribe((res) => {
      this.employees = res;
    });
  }
  add(event: KeyboardEvent): void {
    console.log("add call");
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    const value = input.value.trim();

    if (value && !this.chips.includes(value)) {
      this.chips.push(value);
      input.value = '';
    }
          this.logChips()

  }

  remove(chip: string): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }
    this.logChips()

  }

  // removeLast(event: KeyboardEvent): void {
  //   if (this.chipControl.value === '') {
  //     this.chips.pop();
  //     this.logChips()
  //   }
  // }
  logChips(){
    console.log(this.chips,"chips")
  }
  fetchCandidateTag() {
    this.candidateTagsService.fetchAllCandidateTags().subscribe(res=> {
         this.candidateTags = res;
    })
  }

  sendQualificationRequest()
 {
  console.log("call sendQualificationRequest ")
  let experienceInYear = this.candidateQualificationForm.get('totalExperience').value
  let index = experienceInYear.indexOf('.')
  if( index !== -1) {
    let year = Number(experienceInYear.slice(0,index))
    let month = Number(experienceInYear.slice(index+1, experienceInYear.length))
    this.candidateQualificationForm.controls['totalExperience'].setValue(month + (year*12));
    this.candidateForm.controls['experienceInMonth'].setValue(Number(experienceInYear)*12);
  }
  else {
    this.candidateForm.controls['experienceInMonth'].setValue(Number(experienceInYear)*12);
    this.candidateQualificationForm.controls['totalExperience'].setValue(Number(experienceInYear)*12);

  }

  this.candidateForm.controls['skills'].setValue(this.candidateQualificationModel.candidate.skills)
  this.candidateForm.controls['currentSalary'].setValue(this.candidateQualificationModel.candidate.currentSalary)
  this.candidateForm.controls['expectedSalary'].setValue(this.candidateQualificationModel.candidate.expectedSalary)
  this.candidateForm.controls['requiredQualifications'].setValue(this.candidateQualificationModel.candidate.requiredQualifications)
  this.candidateForm.controls['jobRequisitionId'].setValue(this.candidateQualificationModel.candidate.jobRequisitionId)
  this.candidateForm.controls['reasonToChange'].setValue(this.candidateQualificationModel.candidate.reasonToChange)
 
 console.log(experienceInYear,this.candidateQualificationModel.candidate.skills,this.candidateQualificationModel.candidate.currentSalary,this.candidateQualificationModel.candidate.expectedSalary)


    let totalExperience = this.candidateQualificationForm.get('totalExperience').value
    if( index !== -1) {
      let year = Number(totalExperience.slice(0,index))
      let month = Number(totalExperience.slice(index+1, totalExperience.length))
      this.candidateQualificationForm.controls['totalExperience'].setValue(month + (year*12));
  
    }
    else {
      this.candidateQualificationForm.controls['totalExperience'].setValue(Number(totalExperience)*12);
    }
     
    console.log(totalExperience)



  const approvalData: CandidateQualificationModel = {
    candidate: {
         id:this.candidateModel.id,
        jobRequisitionId: this.candidateModel.jobRequisitionId,
        requiredQualifications: this.candidateModel.requiredQualifications,
        skills: this.candidateModel.skills,
        expectedSalary: this.candidateModel.expectedSalary,
        currentSalary: this.candidateModel.currentSalary,
        reasonToChange: this.candidateModel.reasonToChange
    
    },

    jobRequisitionId:this.candidateModel.jobRequisitionId,
    totalExperience: this.candidateModel.experienceInMonth,
    currentDesignation:this.candidateQualificationModel.currentDesignation,
    approverActionComment:this.candidateQualificationModel.approverActionComment,
    candidateWorkPreference:this.candidateQualificationModel.candidateWorkPreference,
    certifications:this.chips,
    currentCompany:this.candidateQualificationModel.currentCompany,
    noticePeriod:this.candidateQualificationModel.noticePeriod,
    currentAddess:this.candidateQualificationModel.currentAddess,
    homeTown:this.candidateQualificationModel.homeTown,
    candidateQulificationApprovers:this.candidateQualificationModel.candidateQulificationApprovers,
    remark:this.candidateQualificationModel.remark
  };
  console.log(approvalData)
   this.candidteQualificationService.sendCandidateQualificationRequest(approvalData).subscribe((res) => {
    console.log(res)
    this.messageService.display('Candidate qualification request successfull!  Request ID : ' + res, 'OK', 'success');
    this.router.navigate(['/candidate-qualification'])
  });
 }


}
