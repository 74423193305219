import { CandidatePoolService } from 'src/app/core/services/candidate-pool.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CandidateService } from '../../core/services/candidate.service';
import { UserService } from '../../core/services/user.service';
import { EmployeeService } from '../../core/services/employee.service';
import { dateTimeFormatters, base64toBlob } from '../factory/common.service';
import { MessageService } from '../../shared/factory/message.service';
import { ElementRef ,Renderer2} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BoardingModel, BoardingFormBuilder } from '../models/boarding-model';
import { CommonService } from '../../shared/factory/common.service';
import { NotesFormBuilder, NotesModel } from '../models/notes-model';
import { NotesService } from 'src/app/core/services/notes.service';
import { CandidateNotesFormBuilder, CandidateNotesModel } from '../models/candidate-notes-model';
import { $ } from 'protractor';
import { ExistingCandidateFormBuilder, ExistingCandidateModel } from '../models/new-candidate-model';
import { resolve6 } from 'dns';
import { UserCalendarService} from 'src/app/core/services/user-calendar.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CandidateQualificationService } from 'src/app/core/services/candidate-qualification.service';
import { CandidateQualificationApproval, candidateQualificationFormBuilder, CandidateQualificationModel } from '../models/candidate-qualification-model';
import { AppConfigService } from 'src/app/configs';
import { HelpService } from 'src/app/core/services/help.service';

// Candidate Offer Dialog
@Component({
    selector: 'app-candidate-offer-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>local_offer</mat-icon><span>&nbsp;&nbsp;{{data.id ? 'Job Offer' : 'Select Offer Letter'}}</span></h2>
    <hr/>
     <mat-dialog-content>
      <p>
        <label>Offer Letter</label>
        <a mat-raised-button color="warn" class="w-100 mb-2 text-left" (click)="fileInput.click()">
          <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
          <span>{{offerFile.value ? selectedFileName : "Select (.pdf,.doc,.docx)"}}</span>
          <input #fileInput [formControl]="offerFile" type="file" (change)="onFileInput($event)" style="display:none;" accept=".pdf,.doc,.docx" [required]="data.id ? 'false' : 'true'" />
        </a>
        <mat-error *ngIf="offerFile.invalid">Offer Letter is required!</mat-error>
        <mat-form-field class="w-100" appearance="outline">
        <mat-label>Tentative Joining Date</mat-label>
        <input matInput readonly [formControl]="tentativeJoiningDate" [matDatepicker]="datepicker1" placeholder="Select Tentative Joining Date" disabled>
        <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
        <mat-datepicker touchUi="true" #datepicker1 disabled="false"></mat-datepicker>
        <mat-hint>Select Tentative Joining Date</mat-hint>        
    </mat-form-field>
    <input matInput [formControl]="emailContent" value="" hidden>
    <mat-checkbox *ngIf="!data.id" [(ngModel)]="sendEmail" (change)="onSendEmailChange($event)">Send Email</mat-checkbox>
    </p>
     

 <p *ngIf="!data.id && sendEmail">
        <label>Email Content</label>
        <quill-editor id="emailOffer" [formControl]="sendEmailContent" placeholder="Enter email content"></quill-editor>
    </p>


      <p *ngIf="data.id">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Job Offer Status</mat-label>
          <mat-select [formControl]="offerStatus" [required]="data.id ? 'false' : 'true'">
            <mat-option value="OFFERED">Offered</mat-option>
            <mat-option value="REJECTED">Rejected</mat-option>
            <mat-option value="ACCEPTED">Accepted</mat-option>
            <mat-option value="NO_SHOWUP">No Showup</mat-option>
          </mat-select>
          <mat-hint>Select offer status</mat-hint>
          <mat-error *ngIf="offerStatus.invalid">Required!</mat-error>
        </mat-form-field>
      </p>
     </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="offerFile.invalid || offerStatus.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">Send</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class CandidateOfferDialogComponent implements OnInit {

    offerFile = new FormControl('', [this.data.id ? Validators.nullValidator : Validators.required]);
    offerStatus = (this.data.id ? new FormControl(this.data.jobOfferStatus, [Validators.required]) : new FormControl(''));
    tentativeJoiningDate = (this.data.id ? new FormControl(dateTimeFormatters.formatDate(this.data.tentativeJoiningDate)) : new FormControl(''));
    selectedFileName = "";
    offerLetter: any = null;
    sendEmailContent = new FormControl('');
    sendEmail = false;


    constructor(
        public dialogRef: MatDialogRef<CandidateOfferDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService,
        private elementref:ElementRef,
        private render:Renderer2
    ) { }

    ngOnInit(): void {
        if (this.sendEmail) {
          this.fetchDefaultEmailTemplate();
        }
      }

      fetchDefaultEmailTemplate(): void {
        this._candidateService.getDefaultEmailTemplate().subscribe(template => {
          this.sendEmailContent.setValue(template);
        }, error => {
          console.error('Error fetching email template', error);
        });
      }
      onSendEmailChange() {
        if (this.sendEmail) {
            var element = document.getElementById("offerEmail");
            element.parentElement.classList.add("expand-modal");

          this._candidateService.getDefaultEmailTemplate().subscribe(
            res => {           
              res.template = res.template.replace('${candidateName}', `${this.data?.candidate?.firstName} ${this.data?.candidate?.lastName}`);
              res.template = res.template.replace('${jobRequisition}', this.data?.jobRequisition?.name);
              this.sendEmailContent.setValue(res.template);
            },
              error => {
                  console.log(error);
                  if (error.status === 400 && error.error === "Email is required") {
                      this.messageService.display('Please link your email', 'OK', 'error');
                      this.sendEmail = false;
                  } else {
                      this.messageService.display('Failed to load email template', 'OK', 'error');
                      this.sendEmail = false;
                  }
              }
          );
        }else {
            var element = document.getElementById("offerEmail");
            if (element) {
              element.parentElement.classList.remove("expand-modal");
            }
            this.sendEmailContent.setValue('');
          }
      }
    
    onFileInput($event) {
        var regex = /(.*?)\.(docx|doc|pdf)$/;
        if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
            this.selectedFileName = $event.srcElement.files[0].name;
            this.offerLetter = $event.srcElement.files[0];
        }
    }

    applyAction() {
        this.data.jobOfferStatus = null;
        this.data.tentativeJoiningDate = null;
        this.data.emailContent=null;
        this.data.sendEmail = this.sendEmail;
        let formData: FormData = new FormData();
        if (this.offerLetter !== null) formData.append('offerLetter', this.offerLetter, this.offerLetter.name);
        if (this.data.id) {
            this.data.jobOfferStatus = this.offerStatus.value;
            this.data.tentativeJoiningDate = this.tentativeJoiningDate.value;
            let jobOfferDTOBlob = new Blob([JSON.stringify(this.data)], {
                type: 'application/json'
            });
            formData.append('jobOfferDTO', jobOfferDTOBlob);
            this._candidateService.updateOfferLetter(formData).subscribe(res => {
                this.messageService.display('Updating offer letter is successfull!', 'OK', 'success');
                this.dialogRef.close('applied');
            });
        } else {
            this.data.tentativeJoiningDate = this.tentativeJoiningDate.value;
            if (this.sendEmail) {
                const emailOfferElement = document.getElementsByClassName("ql-editor");
                if (emailOfferElement) {
                    var emailContent = emailOfferElement[0].innerHTML;
                   this.data.emailContent = emailContent;
                } else {
                    console.error("Email offer element not found");
                }
            }          
            let jobOfferDTOBlob = new Blob([JSON.stringify(this.data)], {
                type: 'application/json'
            });            
            formData.append('jobOfferDTO', jobOfferDTOBlob);
            
            this._candidateService.sendOfferLetter(formData).subscribe({
                next: (res) => {
                    if (res === -1) {
                        this.messageService.display('Failed to send offer letter. Please try again later.', 'OK', 'error');
                    } else {
                    this.messageService.display('Sending offer letter is successful!', 'OK', 'success');
                    this.dialogRef.close('applied');
                    }
                },
                error: (error) => {
                    console.error('Error sending offer letter:', error);
                        this.messageService.display('An unexpected error occurred. Please try again later.', 'OK', 'error');
                    
                }
            });
        }
    }

}

// Interview Action Dialog
@Component({
    selector: 'app-interview-action-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>feedback</mat-icon><span>&nbsp;&nbsp;Select Action</span></h2>
    <hr/>
    <mat-dialog-content>
      <p class="mb-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Permissions</mat-label>
          <mat-select [formControl]="action" required>
            <mat-option value="">None</mat-option>
            <mat-option value="NEXT_ROUND">Next Round</mat-option>
            <mat-option value="ONHOLD">On Hold</mat-option>
            <mat-option value="SELECTED">Selected</mat-option>
            <mat-option value="REJECTED">Rejected</mat-option>
            <mat-option value="NO_SHOW_UP">No ShowUp</mat-option>
          </mat-select>
          <mat-hint>Select Permissions</mat-hint>
          <mat-error *ngIf="action.invalid">Required!</mat-error>
        </mat-form-field>
      </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="action.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">Apply</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class InterviewActionDialogComponent implements OnInit {

    action = new FormControl('', [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<InterviewActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService
    ) { }

    ngOnInit() { }

    applyAction() {
        let formData = { "id": this.data.interviewId, "interviewRoundId": this.data.roundId, "interviewRoundStatus": this.action.value };
        this._candidateService.updateInterviewAction(formData).subscribe(res => {
            this.messageService.display('Update is successfull!', 'OK', 'success');
            this.dialogRef.close('applied');
        });
    }

}

// Employee Startup Dialog
@Component({
    selector: 'app-employee-startup-dialog',
    template:
        
    
    `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>person</mat-icon><span>&nbsp;&nbsp;Create Employee</span></h2>
    <hr/>
    <mat-dialog-content>
        <p>
            <label>Aggreement File</label>
            <a mat-raised-button color="warn" class="w-100 text-left" (click)="fileInput.click()">
                <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
                <span>{{inputFile.value ? selectedFileName : "Select (.pdf,.doc,.docx)"}}</span>
                <input #fileInput [formControl]="inputFile" type="file" (change)="onFileInput($event)" style="display:none;" accept=".pdf,.doc,.docx" required />
            </a>
            <mat-error *ngIf="inputFile.invalid">Agreement is required!</mat-error>
        </p>
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Manager Employee</mat-label>
                <mat-select [formControl]="managerEmpId" required>
                    <mat-option *ngFor="let employee of data.employeeLite; let m = index;" [value]="employee.employeeLite.id">{{employee.employeeLite.firstName + ' ' + employee.employeeLite.lastName}}</mat-option>
                </mat-select>
                <mat-hint>Select manager employee</mat-hint>
                <mat-error *ngIf="managerEmpId.invalid">Employee required!</mat-error>
            </mat-form-field>
        </p>
        <p>
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Joining Date</mat-label>
                <input matInput readonly [formControl]="dateOfJoining" [matDatepicker]="datepicker1" placeholder="Select joining date" disabled>
                <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
                <mat-datepicker touchUi="true" #datepicker1 disabled="false"></mat-datepicker>
                <mat-hint>Select joining date</mat-hint>
            </mat-form-field>
        </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="inputFile.invalid || managerEmpId.invalid || dateOfJoining.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">Send</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class EmployeeStartupDialogComponent implements OnInit {

    inputFile = new FormControl('', [Validators.required]);
    managerEmpId = new FormControl('', [Validators.required]);
    dateOfJoining = new FormControl('', [Validators.required]);
    selectedFileName = "";
    agreementLetter: any = null;

    constructor(
        public dialogRef: MatDialogRef<CandidateOfferDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService
    ) { }

    ngOnInit() { }

    onFileInput($event) {
        var regex = /(.*?)\.(docx|doc|pdf)$/;
        if (($event.srcElement.files.length > 0) && (regex.test($event.srcElement.files[0].name))) {
            this.selectedFileName = $event.srcElement.files[0].name;
            this.agreementLetter = $event.srcElement.files[0];
        }
    }

    applyAction() {
        let formData: FormData = new FormData();
        if (this.agreementLetter !== null) formData.append('agreementFile', this.agreementLetter, this.agreementLetter.name);
        let employeeDTO = new Blob([JSON.stringify({ "managerEmpId": this.managerEmpId.value, "dateOfJoining": dateTimeFormatters.formatDate(this.dateOfJoining.value) })], {
            type: 'application/json'
        });
        formData.append('employeeDTO', employeeDTO);
        this._candidateService.sendConfirmation(formData, this.data.jobOfferId).subscribe(res => {
            this.messageService.display('Confirmation sending is successfull!', 'OK', 'success');
            this.dialogRef.close('applied');
        });
    }

}

// Profile Picture Dialog (Employee, etc)
@Component({
    selector: 'app-profile-picture-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>{{data.profileSelected ? 'edit' : 'add_a_photo'}}</mat-icon><span>&nbsp;&nbsp;Profile Picture</span></h2>
    <hr/>
    <mat-dialog-content>
        <div class="row">
            <div class="col-md-12">
                <p>
                    <label>Profile Picture</label>
                    <a mat-raised-button color="warn" class="w-100 text-left" (click)="fileInput.click()">
                        <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
                        <span>{{profilePicture.value ? selectedFileName : "Select (.png,.jpg,.jpeg)"}}</span>
                        <input #fileInput [formControl]="profilePicture" type="file" (change)="fileChangeEvent($event)" style="display:none;" accept=".png,.jpg,.jpeg" required />
                    </a>
                    <mat-error *ngIf="profilePicture.invalid">Profile picture is required!</mat-error>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="4 / 5"
                        [resizeToWidth]="256"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>
                </p>
            </div>
            <div class="col-md-6">
                <p>
                    <img [src]="croppedImage" />
                </p>
            </div>
        </div>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="profilePicture.invalid" (click)="applyAction()" mat-raised-button color="primary" class="w-100">{{data.profileSelected ? 'Update' : 'Upload'}} Profile Picture</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class ProfilePictureDialogComponent implements OnInit {

    profilePicture = new FormControl(null, [Validators.required]);
    imageChangedEvent: any = '';
    croppedImage: any = '';
    selectedFileName;

    constructor(
        public dialogRef: MatDialogRef<ProfilePictureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private messageService: MessageService,
        private employeeService: EmployeeService
    ) { }

    ngOnInit() { }

    fileChangeEvent(event: any): void {
        if (this.profilePicture.value) {
            this.selectedFileName = event.srcElement.files[0].name;
            this.imageChangedEvent = event;
        } else {
            this.selectedFileName = null;
            this.imageChangedEvent = '';
            this.croppedImage = '';
        }
    }

    imageCropped(image: string) {
        this.croppedImage = image;
    }

    imageLoaded() {
        // show cropper
    }

    loadImageFailed() {
        this.messageService.display('Image selection failed!', 'OK', 'error');
    }

    applyAction() {
        // Split the base64 string in data and contentType
        var block = this.croppedImage.split(";");
        // Get the content type of the image
        var contentType = block[0].split(":")[1];
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];
        // Convert it to a blob to upload
        var blob = base64toBlob(realData, contentType);
        let formData: FormData = new FormData();
        if (this.profilePicture !== null) formData.append('profilePic', blob, this.selectedFileName);
        this.employeeService.uploadEmployeeProfilePic(this.data.employeeId, formData).subscribe((res) => {
            this.messageService.display('Profile picture is uploaded successfully!', 'OK', 'success');
            this.dialogRef.close({ data: 'uploaded', img: this.croppedImage });
        });
    }

}

// Dashboard Help Dialog
@Component({
    selector: 'app-dashboard-help-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>feedback</mat-icon><b>&nbsp;&nbsp;Help</b></h2>
    <hr/>
    <mat-dialog-content>
        <mat-accordion>
            <mat-expansion-panel class="mt-2" [expanded]="contentId == 0" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>1. Employee Count</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- The total number of members employed at the company over time.</p>
                <p>- Includes currently working and not-working both.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 1" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>2. Interview Statistics</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- The total number of pending, in-progress and on-hold candidates.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 2" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>3. Candidate Count</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- The total number of candidates available right now.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 3" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>4. Candidate Schedule</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Displays interview schedules per date with details.</p>
                <p>- Includes completed interviews.</p>
                <p>- Previous months interview schedules can also be viewed.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 4" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>5. Rejection Ratio</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Rejection Ratio is a measure of rejected candidates to interviewed candidates.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 5" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>6. Requisition</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Department supervisors who want to hire additional staff, or replace employees who resign or are terminated, generally have to complete a job requisition.</p>
                <p>- A requisition is forwarded to the human resources department for handling by the recruiter.</p>
                <p>- The recruiter then places job postings based on the requisition and discussions with the hiring manager or department supervisor.</p>
                <p>- The total number of approved, rejected and open job requisitions.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 6" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>7. Candidates Added</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Total number of candidates added per month for given months.</p>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="contentId == 7" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>8. Interview Activities</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Total number of candidate interviews taken per month for given months.</p>
            </mat-expansion-panel>
            <mat-expansion-panel class="mb-2" [expanded]="contentId == 8" (opened)="scrollToview()">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>9. Shortlisted Candidates</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>- Total number of shortlisted candidates for given positions.</p>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`,
    styles: ['p { margin: 0; }']
})
export class DashboardHelpDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InterviewActionDialogComponent>,
        public myElement: ElementRef,
        @Inject(MAT_DIALOG_DATA) public contentId: any
    ) { }

    ngOnInit() { }

    scrollToview() {
        setTimeout(() => {
            const el = this.myElement.nativeElement.querySelector('.mat-expanded');
            el.scrollIntoView();
        }, 100);
    }
}

@Component({
    selector: 'Boarding-component',
    template: `<div [formGroup]="boardingForm"><h1 mat-dialog-title align="center"> Boarding process </h1>
    <div mat-dialog-content>
        <div class="col-sm">
        <mat-form-field>
            <input matInput placeholder="Company Name" formControlName="companyName">
        </mat-form-field>
        </div>
        <div class="col-sm">
        <mat-form-field>
            <input matInput placeholder="Department Name" formControlName="departmentName">
        </mat-form-field>
        </div>
        <div class="col-sm">
        <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Timezone</mat-label>
                                <mat-select formControlName="timeZone">
                                    <mat-option *ngFor="let timezone of timeZones; let i = index;" [value]="timezone.value + ' - ' + timezone.text">{{timezone.value + ' - ' + timezone.text}}</mat-option>
                                </mat-select>
                                <mat-hint>Select timezone</mat-hint>
         </mat-form-field>
        </div>
       
         </div>
         <div class="col-sm">
             <div mat-dialog-actions align="center">
             <button class="mat-raised-button mat-primary" align="end"(click)="save()">Save</button>
             </div>
         </div>
    </div>`
})

export class BoardingComponent implements OnInit {

    boarding: BoardingFormBuilder = new BoardingFormBuilder();
    boardingModel: BoardingModel = this.boarding.boardingModel;
    boardingForm: FormGroup = this.boarding.boardingForm;
    timeZones;
    countries;

    module = {
        name: ''
    };

    constructor(
        private userService: UserService,
        public router: Router,
        public commonServices: CommonService,
        public messageService: MessageService,
        public dialogRef: MatDialogRef<BoardingComponent>
    ) { }

    ngOnInit() {
        const temp = sessionStorage.getItem('loginData');
        this.boardingModel.moduleName = JSON.parse(temp).moduleName;
        this.commonServices.fetchTimeZones().subscribe(res => {
            this.timeZones = res;
        });
        this.commonServices.fetchCountries().subscribe(res => {
            this.countries = res.countries;
        });
    }
    save() {
        this.userService.checkBoardingStatus(this.boardingModel).subscribe((res) => {
            this.dialogRef.close();
            this.messageService.display('Boarding process is successfully completed ! Module name : ' + this.boardingModel.moduleName, 'OK', 'success');
        });
    }
}

@Component({
    selector: 'app-confirmation-dialog',
    template: `<div mat-dialog-content>
    {{message}}
   </div>
   <div mat-dialog-actions>
     <button mat-button (click)="onNoClick()">Cancel</button>
     <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
   </div>`
})
export class ConfirmationDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public message: string) { }
    
        onNoClick(): void {
        this.dialogRef.close();
    }
   ngOnInit() {
    }

}

//Notes  Dialog
@Component({
    selector: 'app-notes-add-update-dialog',
    template: `<div  [formGroup]="candidateNotesForm">
    
    <div class="mat-card-header" style="margin-bottom:30px;">
        <h3 class="d-inline-flex align-items-center">
          <mat-icon matSuffix class="header-icon">description</mat-icon>&nbsp;&nbsp;
          <span i18n="@@manageNotes">Manage Notes</span>
        </h3>
    </div>
   
    <mat-dialog-content>
        <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-3">
                        <mat-label>Candidate Name</mat-label>
                    </div>
                    <div class="col-sm-3">
                        <input matInput readonly formControlName="firstName">
                    </div>
                    <div class="col-sm-3">
                        <mat-label>Email-Id</mat-label>
                    </div>
                    <div class="col-sm-3">
                        <input matInput readonly formControlName="email">
                    </div>
                    </div>
        </div>

        <hr />
        <div class="col-sm-12">
            
            <div class="row">
                <div class="col-sm-3">
                    <mat-label> Address</mat-label>
                </div>
                <div class="col-sm-3">
                    <input matInput readonly formControlName="address">
                </div>
                <div class="col-sm-3">
                    <mat-label>Mobile Number</mat-label>
                </div>
                <div class="col-sm-3">
                    <input matInput readonly formControlName="mobileNumber">
                </div>
                </div>
    </div>
        <hr />

        <div class="row">
			<div class="col-sm" style="width: 85%;">
				<p>
					<mat-form-field class="w-100" appearance="outline">
						<mat-label>Add Notes</mat-label>
						<textarea formControlName="note" matInput placeholder="Enter Candidate Note" value="{{textInput}}"> </textarea>
						<mat-hint>Enter Note</mat-hint>
						<mat-error *ngIf="candidateNotesForm.controls.note.invalid">Candidate Notes is required!</mat-error>
					</mat-form-field>
				</p>
			</div>
			<div class="text-right" style="float:right;">
            <button  type="submit" mat-raised-button color="primary" class="mat-control w-20 mt-2 center" (click)="AddNotes()"
            [disabled]="candidateNotesForm.controls.note.invalid" style="top: 30px;">Add Notes
            </button>
        </div>
		</div>
    
    
    <hr />

    <div style="overflow-x: hidden;height: auto;">

        <div *ngFor="let notee of CandidateNotes;">
            <mat-card style="padding: 10px; margin:15px;">
                <mat-card-content
                    style="overflow-x:unset;border:solid grey 1px;height:auto;border-radius: 5px;padding-left: 5px;background-color: lightgrey;" id="{{notee.id}}">
                    <div (click)="addUpdateNotes($event)"> {{notee?.note}} </div>      
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</mat-dialog-content>
    <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close mat-stroked-button class="mat-focus-indicator mat-stroked-button mat-button-base">Close</button>
  </mat-dialog-actions>

</div>

`
  })
  export class NotesComponent implements OnInit {

    notes: NotesFormBuilder = new NotesFormBuilder();
    notesModel: NotesModel = this.notes.notesModel;
    notesForm: FormGroup = this.notes.notesForm;

    candidateNotes: CandidateNotesFormBuilder = new CandidateNotesFormBuilder();
    candidateNotesModel: CandidateNotesModel = this.candidateNotes.candidateNotesModel;
    candidateNotesForm: FormGroup = this.candidateNotes.candidateNotesForm;
    dataSource: MatTableDataSource<CandidateNotesModel>;
  
    param1: number;
    CandidateNotes = [];
    myInput=null;
    textInput= "";
    constructor(
     @Inject(MAT_DIALOG_DATA) public data: any,
      private notesService: NotesService,
      public router: Router,
      private route: ActivatedRoute,
      public messageService: MessageService,
      public dialogRef: MatDialogRef<NotesComponent>,
      private _host: ElementRef
    ) { 
    }
  ngOnInit(){
    this.candidateNotes.setModelVals(this.data.res);
    this.CandidateNotes = this.data.res.notes;
  }

  fetchNotes() {
        this.notesService.fetchNotes(this.data.candidateId).subscribe((res: CandidateNotesModel) => {
            this.candidateNotes.setModelVals(res);
            this.CandidateNotes = res.notes;
          });
  }

  AddNotes() {
    var retVal = confirm("Do you want to Add Notes?");
 //   console.log(this.notesModel.note)
    if (retVal == true) {
      this.notesModel.note = this.candidateNotes.candidateNotesModel.note;
      this.notesModel.candidateId = this.data.candidateId;
     
      if (this.myInput) {
          this.notesModel.id = this.myInput;
        this.notesService.updateNotes(this.notesModel).subscribe((res) => {
            this.messageService.display('Notes updated  successfully!', 'OK', 'success');
            this.fetchNotes();
          });
    } else {
        this.notesService.addNotes(this.notesModel).subscribe((res) => {
            this.messageService.display('Notes added  successfully!', 'OK', 'success');
            this.fetchNotes();   
          });
    }
    }
    else {
      return false;
    }
  }

  addUpdateNotes($event) {
    var id = $event.currentTarget.parentNode.getAttribute('id');
    var txt = $event.currentTarget.innerText;
    this.myInput = id;
    this.textInput = txt;
    this.candidateNotes.candidateNotesModel.note = txt;
    //console.log($event.currentTarget.parentNode.previousElementSibling.innerText);
  }
}


  // Add Candidate to Pool Dialog

@Component({
    selector: 'app-add-candidate-to-pool',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>supervisor_account</mat-icon><span>&nbsp;&nbsp; Add Candidate To Candidate Pool</span></h2>
    <hr/>
    <form [formGroup]="existingCandidateForm">
    <mat-dialog-content>
      <p class="mb-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Candidate Pool</mat-label>
          <mat-select multiple formControlName="candidatePools" required >
            <mat-option *ngFor="let allCandidatePool of candidatePool" [value]="allCandidatePool.id">{{allCandidatePool.name}}</mat-option>
          </mat-select>
          <mat-hint>Select candidate pool</mat-hint>
          <mat-error *ngIf="existingCandidateForm.controls.candidatePools.invalid">Select candidate pool is required!</mat-error>
        </mat-form-field>
      </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button  (click)="addCandidateToPool()"  [disabled]="existingCandidateForm.invalid" mat-raised-button color="primary" class="w-100">Add</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>
    </form>`
})
export class AddCandidateToPoolComponent implements OnInit {

    existingCandidateFormBuilder: ExistingCandidateFormBuilder = new ExistingCandidateFormBuilder();
    existingCandidateModel: ExistingCandidateModel = this.existingCandidateFormBuilder.existingCandidateModel;
    existingCandidateForm: FormGroup = this.existingCandidateFormBuilder.existingCandidateForm;
    candidatePool: any;
    candidatePoolId = [];
    constructor(
        public dialogRef: MatDialogRef<AddCandidateToPoolComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService,
    ) { }

    ngOnInit() {
        this.candidatePool = this.data.res;
     }

     addCandidateToPool() {
        this.existingCandidateModel.candidateId = this.data.candidatePoolId;
        this._candidateService.addCandidateToPool(this.existingCandidateModel).subscribe(res => {
        this.messageService.display('Add candidate to candidate pool is successfull! Candidate ID : ' + res, 'OK', 'success');
         this.dialogRef.close();
       });
    }
}

//Dialog for Delete Candidate 

@Component({
    selector: 'app-delete-candidate',
    template: `<div  mat-dialog-title><span>Confirm Deletion</span>
    </div>
    <div mat-dialog-content style="margin-top:20px">
      <p style="font-size:16px;">Are you sure you want to delete Candidate? As this will also delete all associated data.</p>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button (click)="onNoClick()">No</button>
      <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial (click)="onYesClick()">Yes</button>
    </div>`
})
export class DeleteCandidateComponent implements OnInit {

    candidateId: number;
    constructor(
        public dialogRef: MatDialogRef<DeleteCandidateComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService,
    ) { }

    ngOnInit() {
        this.candidateId = this.data.candidateId;
     }

     onNoClick(): void {
        this.dialogRef.close();
      }

      onYesClick() {
        this._candidateService.deleteCandidate(this.candidateId).subscribe(res=>{
            this.messageService.display('Candidate successfully deleted.', 'OK', 'success');
            this.dialogRef.close('applied');
        }, err => {
            this.messageService.display(err.error.errors['Deletion failed'][0].message, 'OK', 'error');
            this.dialogRef.close();
        });
      }
}

//Revoke Feedback


@Component({
    selector: 'app-revoke-feedback',
    template: `<div  mat-dialog-title><span>Confirm Revoke Feedback</span>
    </div>
    <div mat-dialog-content style="margin-top:20px">
      <p style="font-size:16px;">Are you sure you want to revoke feedback of {{penddingFeeedbackData.name}} for interviewer {{penddingFeeedbackData?.interviewers[this.index]?.interviewer?.firstName + penddingFeeedbackData?.interviewers[this.index]?.interviewer?.lastName}}? </p>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button (click)="onNoClick()">No</button>
      <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial (click)="onYesClick()">Yes</button>
    </div>`
})
export class RevokeFeedbackComponent implements OnInit {

    interviewRoundId: number;
    interviewerId: number;
    penddingFeeedbackData: any;
    index: number;
    constructor(
        public dialogRef: MatDialogRef<RevokeFeedbackComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService,
    ) { }

    ngOnInit() {
        this.penddingFeeedbackData = this.data.res;
        this.interviewRoundId = this.data?.res.id;
        this.index = this.data?.index;
        this.interviewerId = this.data?.res?.interviewers[this.index]?.id;
     }

     onNoClick(): void {
        this.dialogRef.close();
      }

      onYesClick() {
        this._candidateService.revokeFeedback(this.interviewRoundId, this.interviewerId).subscribe(res=>{
            this.messageService.display('feedback revoked successfully', 'OK', 'success');
            this.dialogRef.close('applied');
        }, err => {
            this.dialogRef.close();
        });
      }
}

// feednack request


@Component({
    selector: 'app-feedback-request-dialog',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>feedback</mat-icon><span>&nbsp;&nbsp;Add Interviewer To Round</span></h2>
    <hr/>
    <mat-dialog-content>
      <p class="mb-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Interviewer</mat-label>
          <mat-select name="feddbackRequest" [formControl]="interviewers" multiple  required>
          <ng-container *ngFor="let employee of employeesList">
          <mat-option *ngIf="employee?.isChecked;else selectemployees" [value] = "employee.id" [disabled] = "true" class="filter-option">
            <input type="checkbox" [checked] = "true" [disabled] = "true" class="filter-options">
            {{employee.name}}
          </mat-option>
          <ng-template #selectemployees>
          <mat-option  [value] = "employee">{{employee.name}}</mat-option>
          </ng-template>
          </ng-container>
          </mat-select>
          <mat-hint>Select Interviewer</mat-hint>
          <mat-error *ngIf="interviewers.invalid">Required!</mat-error>
        </mat-form-field>
      </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="interviewers.invalid" (click)="addInterviewersToRound()" mat-raised-button color="primary" class="w-100">Add To Interview Round</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class FeedbackRequestDialogComponent implements OnInit {

    interviewers = new FormControl([], [Validators.required]);
    employeesList = [];
    roundDetails:any; 
    payload:object = {}

    constructor(
        public dialogRef: MatDialogRef<FeedbackRequestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _candidateService: CandidateService,
        private messageService: MessageService,
        private employeeService: EmployeeService
    ) { }

    ngOnInit() {
        this.roundDetails = this.data.res?.interviewers;
        this.allFetchEmployees();
     }

     allFetchEmployees() {
        this.employeesList = [];
        this.employeeService.fetchEmployeesLite().subscribe(res=>{
            for(let i=0; i<res.length; i++) {
               let isChecked  = false;
            this.roundDetails.filter(value => {
                if(res[i].id === value.interviewer.id) {
                    isChecked = true;
                }
            });
            this.employeesList.push({id: res[i].id, name: res[i].firstName+ " " +res[i].lastName, isChecked: isChecked});
            }
            
          });
     }

     addInterviewersToRound() {
         this.interviewers.value.filter(res=>{
             delete res.isChecked;
         });
        let formData = {"interviewRoundId": this.data.res.id, "employees": this.interviewers.value };
        this._candidateService.addInterviewersToRound(formData).subscribe(() => {
            this.messageService.display('interviewers successfully added to round!', 'OK', 'success');
            this.dialogRef.close('applied');
        });
    }
}

@Component({
    selector: 'app-calendar-summary-list',
    template: `<h2 mat-dialog-title class="d-inline-flex align-items-center mb-0"><mat-icon matSuffix>calendar_today</mat-icon><span>&nbsp;&nbsp;Add Calendar Type</span></h2>
    <hr/>
    <mat-dialog-content>
      <p class="mb-0">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Calendar</mat-label>
          <mat-select name="feddbackRequest" [formControl]="calendars" required>
          <ng-container *ngFor="let calenderType of calendarList">
          <mat-option  [value] = "calenderType.id">{{calenderType.name}}</mat-option>
          </ng-container>
          </mat-select>
          <mat-hint>Select calendar type</mat-hint>
          <mat-error *ngIf="calendars.invalid">Calendar type is required!</mat-error>
        </mat-form-field>
      </p>
    </mat-dialog-content>
    <hr/>
    <div >
      <button (click)="addSelectedCalendar()" mat-raised-button color="primary" class="w-100" [disabled]="calendars.invalid">Add</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2">Cancel</button>
    </div>`
})
export class CalendarSummaryComponent implements OnInit {

    calendarList = [];
    calendars = new FormControl('', [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<CalendarSummaryComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userCalendarService: UserCalendarService,
        private messageService: MessageService
    ) { }

    ngOnInit() {
        this.calendarList = this.data.res;
        this.getCalendarSummary();
     }

     addSelectedCalendar() {
         let calendarSummaryDTO = {calendarLinkId: this.data.calendarId, calendarSummaryId: this.calendars.value}
         this.userCalendarService.updateCalendarLink(calendarSummaryDTO).subscribe(()=>{
            this.messageService.display('Linked calendar type sucessfully added!', 'OK', 'success');
            this.dialogRef.close('applied');
         });      
     }

     getCalendarSummary() {
        this.userCalendarService.fetchCalendarSummary(this.data.calendarId).subscribe(res=> {
            this.calendars.setValue(res.calendarSummaryId)
        });
    }
}


//confirmation box


@Component({
    selector: 'app-confirm-box',
    template: `<div  mat-dialog-title><span>{{title}}</span>
    </div>
    <div mat-dialog-content style="margin-top:20px">
      <p style="font-size:16px;">{{message+"?"}} </p>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button (click)="onNoClick()">No</button>
      <button mat-flat-button color="warn" [mat-dialog-close]="true" cdkFocusInitial (click)="onYesClick()">Yes</button>
    </div>`
})
export class ConfirmationBoxComponent implements OnInit {

    title:string;
    message:string;
    constructor(
        public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.title = this.data.title;
        this.message = this.data.message;
     }

     onNoClick(): void {
        this.dialogRef.close();
      }

      onYesClick() {
            this.dialogRef.close("applied");
      }
}
// candidate resume upload 
@Component({
    selector: 'app-candidate-resume-upload-dialog',
    template: `
       <h2 mat-dialog-title class="d-inline-flex align-items-center mb-0">
      <mat-icon matSuffix>local_offer</mat-icon>
      <span>&nbsp;&nbsp;Attach Candidate Resume</span>
    </h2>
    <hr/>
    <mat-dialog-content>
      <p>
        <a mat-raised-button color="warn" class="w-100 mb-2 text-left" (click)="fileInput.click()"  [disabled]="isLoading">
          <mat-icon matSuffix>file_copy</mat-icon>&nbsp;&nbsp;
          <span>{{offerFile.value ? selectedFileName : "Select (.pdf,.doc,.docx)"}}</span>
          <input #fileInput [formControl]="offerFile" type="file" (change)="onFileInput($event)"  style="display:none;" accept=".pdf,.doc,.docx" />
        </a>
        <mat-error *ngIf="offerFile.invalid">Candidate Resume Attachment is required!</mat-error>
      </p>
    </mat-dialog-content>
    <hr/>
    <div class="text-right">
      <button [disabled]="!selectedFile"   [disabled]="isLoading" (click)="uploadResume()" mat-raised-button color="primary" class="w-100">Upload</button>
      <button mat-raised-button mat-dialog-close class="w-100 mt-2"    (click)="cancel()">Cancel</button>
    </div>
    `
  })
  export class CandidateResumeUploadDialogComponent implements OnInit {
    offerFile = new FormControl('', Validators.required);
  selectedFile: File | null = null;
  isLoading: boolean = false;
  selectedFileName = ""
  
  constructor(
    public dialogRef: MatDialogRef<CandidateResumeUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private candidateService: CandidateService,
    private messageService: MessageService
  ) {}

  ngOnInit() {}

  onFileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) 
        {
      this.selectedFile = input.files[0];
      this.selectedFileName = input.files[0].name;
      this.offerFile.setValue(this.selectedFile);
    }
  }

  uploadResume() {
    if (!this.selectedFile) {
      return;
    }
    this.isLoading = true; 
    const formData = new FormData();
    formData.append('file', this.selectedFile);

    this.candidateService.uploadResume(formData).subscribe(
      response => {
        this.isLoading = false;
        if (response && response.candidateId) {
          this.messageService.display('Candidate resume upload is successful!', 'OK', 'success');
          this.dialogRef.close({ success: true, candidateId: response.candidateId });
        } else {
          this.dialogRef.close({ success: false });
        }
      },
      error => {
        this.isLoading = false;
        console.error('Error uploading resume:', error);
        this.dialogRef.close({ success: false });
      }
    );
  }
  cancel() {
    this.dialogRef.close(null);
  }
 }




 // pop up for view full details of candidate with qualification
 @Component({
  selector: 'view-candidate-with-qualification-details',
  template: `
  <div class="float-right" style="margin-top: -5px;" [formGroup]="candidateQualificationForm">
  <mat-icon mat-dialog-close="true" class="icon-clear">clear</mat-icon>
  </div>
  <div class="text-center" mat-dialog-title><span>Candidate  Details</span></div>

  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>ID :</b> {{data.dataSource.candidate.id}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>FirstName :</b> {{data.dataSource.candidate.firstName}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>LastName :</b> {{data.dataSource.candidate.lastName}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Email:</b> {{data.dataSource.candidate.contact.email}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Phone Number :</b> {{data.dataSource.candidate.contact.primaryPhoneNumber}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Experience In Month:</b> {{data.dataSource.candidate.experienceInMonth}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Apply job Role for :</b> {{jobRequisitionName}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Skills :</b> {{skillNames.join(', ')}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Work Preference :</b> {{data.dataSource.candidateWorkPreference}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Education :</b> {{qualificationNames.join(', ')}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Certificate :</b> {{data.dataSource.certifications}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Current Company :</b> {{data.dataSource.currentCompany}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Reason to Change :</b> {{data.dataSource.candidate.reasonToChange}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Current CTC :</b> {{data.dataSource.candidate.currentSalary}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Expected CTC :</b> {{data.dataSource.candidate.expectedSalary}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Current Designation:</b> {{data.dataSource.currentDesignation}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Current Location :</b> {{data.dataSource.currentAddess}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Native Place :</b> {{data.dataSource.homeTown}}</p>
    </div>
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Notice period :</b> {{data.dataSource.noticePeriod}}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <p style="font-size:16px;"><b>Remark :</b> {{data.dataSource.remark}}</p>
        </div>
  </div>
  <div class="row">
    <div class="col-sm" mat-dialog-content>
      <mat-form-field style="font-size:14px;height: 110px;margin-left:10px; width: 250px;">
        <mat-label>Approver comment</mat-label>
        <textarea matInput formControlName="approverActionComment" #selectValueInput></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row" style="margin-right:0">
    <div class="col-sm" mat-dialog-actions class="button-container">
      <button mat-flat-button color="primary" (click)="onApprove()">Approve</button> 
      <button mat-flat-button color="warn" (click)="onReject()">Reject</button>
      <button mat-flat-button class="green-button" (click)="downloadResume($event)">Download Resume</button>
        <form #downloadForm
      [action]="_configUrls.tempApiPath + 'api/v1/candidate/resume/' + data.dataSource.candidate.id"
      method="post"
      target="_blank"
      style="display: none;">
    <input type="hidden" name="x-auth-token" [value]="auth_token" />
</form>
     </div>
  </div>
`,
styles: [`
  .icon-clear {
    font-size: 24px;
  }
    .green-button {
  background-color: #4CAF50; 
  color: white;
`]
})
export class ViewCandidateQualificationDetailsComponent implements OnInit {
  @ViewChild("selectValueInput") selectValueInput: ElementRef;
  @ViewChild("downloadForm") downloadForm: ElementRef<HTMLFormElement>; // Reference to the hidden form

  candidateQualificationForm: FormGroup;

  qualifications = this.data.qualifications; // Access qualifications data from dialog data
  skills = this.data.skills; // Access skills data from dialog data
  jobRequisitions = this.data.jobRequisitions; // Access job requisitions data from dialog data
  qualificationNames: string[] = [];
  skillNames: string[] = [];
  jobRequisitionName: string = '';
  auth_token = sessionStorage.getItem('x-auth-token');

  constructor(
    public dialogRef: MatDialogRef<ViewCandidateQualificationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private candidateQualificationService: CandidateQualificationService,
    private fb: FormBuilder,
    private messageService: MessageService,
    public router: Router,
    public _configUrls: AppConfigService,private helpService: HelpService
  ) {
    this.candidateQualificationForm = this.fb.group({
      candidate: this.fb.group({
        id: [this.data.dataSource.candidate.id],
        firstName: [this.data.dataSource.candidate.firstName],
        lastName: [this.data.dataSource.candidate.lastName],
        jobRequisitionId: [this.data.dataSource.candidate.jobRequisitionId]
      }),
      id: [this.data.dataSource.id],
      currentDesignation: [this.data.dataSource.currentDesignation],
      approverActionComment: [''] // Initialize FormControl as a string
    });
  }

  ngOnInit() {
    this.qualificationNames = this.getQualificationNames(this.data.dataSource.candidate.requiredQualifications);
    this.skillNames = this.getSkillNames(this.data.dataSource.candidate.skills);
    this.jobRequisitionName = this.getJobRequisitionName(this.data.dataSource.jobRequisitionId); // Single ID
  }

  getQualificationNames(ids: number[]): string[] {
    return ids.map(id => {
      const qualification = this.qualifications.find(q => q.id === id);
      return qualification ? qualification.name : 'Unknown';
    });
  }

  getSkillNames(ids: number[]): string[] {
    return ids.map(id => {
      const skill = this.skills.find(s => s.id === id);
      return skill ? skill.name : 'Unknown';
    });
  }

  getJobRequisitionName(id: number): string {
    const jobRequisition = this.jobRequisitions.find(jr => jr.id === id);
    return jobRequisition ? jobRequisition.jobRequisitionCode + " " + jobRequisition.name : 'Unknown';
  }

  onApprove(): void {
    const approverActionCommentValue = this.selectValueInput.nativeElement.value;


    const candidateQualificationApprover: CandidateQualificationApproval = {
      candidate: this.candidateQualificationForm.value.candidate,
      id: this.candidateQualificationForm.value.id,
      currentDesignation: this.candidateQualificationForm.value.currentDesignation,
      approverActionComment: approverActionCommentValue 
    };
    console.log(approverActionCommentValue);
    this.candidateQualificationService.approveCandidate(candidateQualificationApprover).subscribe(
      response => {
        if (response) {
          this.messageService.display('Candidate qualification successfully approved for candidate ID ' + response, 'OK', 'success');
          this.dialogRef.close();
        } else {
          this.dialogRef.close({ success: false });
        }
      }
    );
  }

  onReject(): void {
    const approverActionCommentValue = this.selectValueInput.nativeElement.value;

    const candidateQualificationApprover: CandidateQualificationApproval = {
      candidate: this.candidateQualificationForm.value.candidate,
      id: this.candidateQualificationForm.value.id,
      currentDesignation: this.candidateQualificationForm.value.currentDesignation,
      approverActionComment: approverActionCommentValue 
    };
    console.log(this.candidateQualificationForm.value);
    this.candidateQualificationService.rejectCandidate(candidateQualificationApprover).subscribe(
      response => {
        if (response) {
          this.messageService.display('Candidate qualification successfully rejected for candidate ID ' + response, 'OK', 'success');
          this.dialogRef.close();
        } else {
          this.dialogRef.close({ success: false });
        }
      }
    );
  }
  downloadResume(event:Event) {
    event.preventDefault();
    this.downloadForm.nativeElement.submit(); 
  }
}

